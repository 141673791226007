<template>
  <q-table flat bordered :data="reports" row-key="id" :columns="columns" :pagination="pagination">
    <template v-slot:bottom>
      <!-- <pagination-table
        @change="getCaptureReport"
        :data="reqPagination"
        :pagination="pagination"
      /> -->
      <q-toolbar class="bg-transparent" :class="!$q.dark.isActive ? 'text-dark' : 'text-white'">
        Total:{{ reqPagination.total }}
        <q-space />
        <q-pagination
          unelevated
          v-model="currentPage"
          color="primary"
          :max="reqPagination.last_page"
          :max-pages="6"
          boundary-numbers
          direction-links
        />
      </q-toolbar>
    </template>
  </q-table>
</template>

<script>
import ReportMixin from '../../mixins/ReportMixin';
//import PaginationTable from "../../widgets/PaginationTable.vue";
export default {
  //  components: { PaginationTable },
  name: 'ListCaptureReports',
  mixins: [ReportMixin],

  data() {
    return {
      columns: [
        {
          name: 'orderId',
          required: true,
          label: 'Order ID',
          align: 'left',
          field: 'orderId',
          sortable: true
        },
        {
          name: 'actionId',
          required: true,
          label: 'Action ID',
          align: 'left',
          field: 'actionId',
          sortable: true
        },
        {
          name: 'status',
          required: true,
          label: 'Status',
          align: 'left',
          field: 'status',
          sortable: true,
          format: (status) => this.labelStatus(status)
        }
      ],
      pagination: {
        sortBy: 'created_at',
        descending: true,
        rowsPerPage: 10
      }
    };
  },

  watch: {
    currentPage() {
      if (this.reqPagination.from != this.reqPagination.current_page) {
        this.getCaptureReport(`page=${this.currentPage}`, `%26perPage=${this.pagination.rowsPerPage}`);
      }
    }
  },

  computed: {
    currentPage: {
      get() {
        return this.reqPagination.current_page;
      },
      set(value) {
        this.ActionSetPagination({
          ...this.reqPagination,
          current_page: value
        });
      }
    }
  },

  created() {
    this.ActionSetFilterReports();
    this.ActionSetReports();
    this.getCaptureReport(null, '&per_page=1' + this.pagination.rowsPerPage);
  },

  methods: {
    labelStatus(status) {
      switch (status) {
        case '0':
          return 'Criado';
        case '1':
          return 'Aprovado';
        case '2':
          return 'Negado';
        case '3':
          return 'Negado - Dados Incompletos';
        case '4':
          return 'Negado - Erro na Preparação';
        case '5':
          return 'Negado - Excesso de Carracteres';
        case '6':
          return 'Negado - Valores Inválidos';
        case '7':
          return 'Negado - Transação Duplicada';
        case '8':
          return 'Negado - Falha ao Salvar';
        case '9':
          return 'Negado - Transação duplicada (exclusivo Méliuz)';
        case '10':
          return 'Negado - Campanha não encontrada na lista de clicks';
        case '11':
          return 'Negado - Erro na limpeza do price';
        case '12':
          return 'Negado - Erro conversão do price';
        case '13':
          return 'Negado - Erro na limpeza do creative id';
        case '14':
          return 'Negado - Falha na montagem da transação';
        case '15':
          return 'Negado - Falha em salvar na Raw Transactions';
        case '16':
          return 'Negado - Transação aguardando possível click';
        case '23':
          return 'Enviado para a Raw Transactions';
        case '24':
          return 'Recebido na Raw Transactions';
        case '17':
          return 'Bloqueado - Canal Removido da Campanha';
        case '18':
          return 'Negado - Tempo de cookie expirado';
        default:
          return '';
      }
    }
  }
};
</script>
