<template>
  <q-layout>
    <div class="page-header">
      <q-toolbar-title class="text-5 q-mr-auto q-ma-sm">Relatório de Captação</q-toolbar-title>
      <q-breadcrumbs class="q-mr-auto q-ma-sm">
        <q-breadcrumbs-el label="Painel de Controle" to="/ " />
        <q-breadcrumbs-el label="Relatório de Captação" exact />
      </q-breadcrumbs>
    </div>

    <q-page padding>
      <header-capture-reports></header-capture-reports>
      <list-capture-reports></list-capture-reports>
    </q-page>
  </q-layout>
</template>

<script>
import HeaderCaptureReports from '../../components/reports/HeaderCaptureReports.vue';
import ListCaptureReports from '../../components/reports/ListCaptureReports.vue';
import ReportMixin from '../../mixins/ReportMixin';

export default {
  components: { HeaderCaptureReports, ListCaptureReports },
  name: 'ClickReports',
  mixins: [ReportMixin]
};
</script>
