<template>
  <div>
    <q-toolbar class="q-pr-none q-mb-md justify-end">
      <!-- <q-input outlined v-model="search" dense type="text" label="Pesquisar">
        <template v-slot:prepend>
          <q-icon name="eva-search-outline" />
        </template>
      </q-input> -->
      <q-btn
        outline
        icon="mdi-filter-outline"
        color="primary"
        no-caps
        class="q-px-sm q-mx-sm"
        :label="$t('filter')"
        @click="filter = !filter"
      >
        <q-tooltip> {{ $t('filter_report') }} </q-tooltip></q-btn
      >
      <q-btn
        outline
        color="primary"
        icon-right="archive"
        class="q-mr-sm"
        no-caps
        @click="exportTableCsv(prepareItemCSV(reports), prepareColumnCSV(columns), 'reports')"
        unelevated
      >
        <q-tooltip> {{ $t('export_csv') }} </q-tooltip>
      </q-btn>
    </q-toolbar>
    <q-intersection transition="slide-down" v-show="filter">
      <q-card flat v-if="filter" bordered class="q-mb-sm">
        <q-toolbar>
          <!-- <q-toolbar-title> {{ $t("filter") }} </q-toolbar-title> -->
          <q-btn flat round dense class="q-ml-auto" icon="mdi-close" @click="filter = false" />
        </q-toolbar>
        <q-card-section class="q-pt-none row q-col-gutter-md">
          <div class="col-6">
            <q-input outlined v-model="params.orderId" type="text" label="Order ID" />
          </div>
          <div class="col-6">
            <q-input outlined v-model="params.actionId" type="text" label="Action ID" />
          </div>
          <div class="col-12 text-right">
            <q-btn color="primary" :label="$t('clear')" no-caps outline class="q-px-md q-mx-md" @click="clearFilter" />
            <q-btn color="positive" :label="$t('search')" no-caps unelevated class="q-px-md" @click="onFilterReports" />
          </div>
        </q-card-section>
      </q-card>
    </q-intersection>
  </div>
</template>

<script>
import ReportMixin from '../../mixins/ReportMixin';

export default {
  name: 'HeaderCaptureReports',
  mixins: [ReportMixin],
  data() {
    return {
      filter: true,
      params: {
        orderId: null,
        actionId: null
      }
    };
  },

  methods: {
    clearFilter() {
      this.params = {
        orderId: null,
        actionId: null
      };

      this.ActionSetFilterReports();
      this.getCaptureReport();
    },

    onFilterReports() {
      let filterSelect = [];

      if (this.params.orderId) filterSelect.push(`order_id=${this.params.orderId}`);
      if (this.params.actionId) filterSelect.push(`action_id=${this.params.actionId}`);
      this.ActionSetFilterReports(filterSelect.join('&'));
      this.getCaptureReport();
    }
  }
};
</script>
